import React from 'react';
import QueueAnim from 'rc-queue-anim';
import {Row, Col, Button,Tabs,List} from 'antd';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import Animate from 'rc-animate';
import {get} from "../utils/http";
import {baseFileUrl} from "../utils/url";
const { TabPane } = Tabs;
class News0 extends React.PureComponent {
  constructor(props) {
    super(props);
    this.carouselRef = React.createRef();
    this.state = {
      current: '1',
      id:'0',
      infoList:[]
    };
  }
  onMouse=(key)=>{
    this.setState({id:key})
  }
  componentDidMount() {
    this.getNewsList()
  }
  getNewsList=()=>{
    let param={column: 'sortNum', order: 'asc',isShow:1,pageSize:5,pageNo:1}
    get('lrWebNews/list',{newsType:this.state.current,...param}).then(res => {
      if(res.success){
        this.setState({
          infoList:res.result.records,
          id:res.result.records.length>0?res.result.records[0].id:'0'
        })
      }
    })
  }
  callback=(key)=>{
    this.setState({
      current: key,
      infoList:[],
    }, () => {
      setTimeout(()=>{
        this.getNewsList()
      },500)
    });
  }
  getBlockChildren = (data) =>
    data.map((item, i) => {
      const {newsImg } = item;
      return (
        <li key={item.id}  md={4} xs={12} onMouseOver={()=>this.onMouse(item.id)}>
          {/*<Animate*/}
          {/*    component=""*/}
          {/*    transitionName="fade"*/}
          {/*>*/}
          {this.state.id===item.id?
            <Row key={i.toString()}>
              <Col span={6}>
                <div className='news0-image'>
                  <img src={baseFileUrl+newsImg} alt="img" width={180} height={120} />
                </div>
              </Col>
              <Col span={18}>
                <QueueAnim className='news0-textWrapper' type="bottom">
                  <div className='news0-title'>{item.newsTitle}</div>
                  <div className='news0-date'>{item.newsDate}</div>
                  <div className='news0-content'>{this.props.isMobile&&item.newsSketch.length>50?item.newsSketch.substring(0,50)+'...':item.newsSketch}</div>
                  <div style={{textAlign:'right'}}><Button type="primary" onClick={()=>this.props.history.push({pathname:'newsIndex',state:item})}>了解详情</Button></div>
                </QueueAnim>
              </Col>
            </Row>
              :
              <div key={i.toString()}>
                <Row>
                  <Col span={18} style={{textAlign:'left'}}>
                    <div className='news0-title'><span style={{fontSize:'10px'}}>●</span> {item.newsTitle}</div>
                  </Col>
                  <Col span={6} style={{textAlign:'right'}}>
                    <div className='news0-date'>{item.newsDate}</div>
                  </Col>
                </Row>
              </div>
          }
          {i<data.length-1?<div key={i.toString()} style={{backgroundColor:'#eeeeee',height:'1px',marginTop:'10px',marginBottom:'10px',marginLeft:'10px',marginRight:'10px'}}/>:null}
          {/*</Animate>*/}
        </li>
      );
    });

  render() {
    const { infoList } = this.state;
    const listChildren = this.getBlockChildren(infoList);
    return (
      <div className='home-page-wrapper news0-wrapper'>
        <div className='home-page news0'>
          <OverPack playScale={0.3}>

            <QueueAnim type="bottom" key="tween" leaveReverse>
              <QueueAnim
                  className='block-wrapper'
                  type="bottom"
                  key="block"
              >
              <div className='blueFont bigTitle' style={{textAlign:'center'}}>新闻动态</div>
              <Tabs defaultActiveKey="1" onChange={this.callback}>
                <TabPane tab={<div style={{fontSize:'20px'}}>公司动态</div>} key="1">
                </TabPane>
                <TabPane tab={<div style={{fontSize:'20px'}}>行业资讯</div>} key="3">
                </TabPane>
                {/*<TabPane tab="媒体报道" key="2">*/}
                {/*</TabPane>*/}
                <TabPane tab={<div style={{fontSize:'20px'}}>专业知识</div>} key="4">
                </TabPane>
              </Tabs>

                <QueueAnim component="ul" type={['right', 'left']} leaveReverse style={{marginBottom:'20px'}}>
                  {listChildren}
                </QueueAnim>
              </QueueAnim>
            </QueueAnim>
          </OverPack>
        </div>
      </div>
    );
  }
}

export default News0;
