import React from 'react';
import {Row,Col} from "antd";
import './less/custom.less'
import yun from '../static/yun.png'
import shuju from '../static/shuju.png'
import fuwu from '../static/fuwu.png'
class Banner extends React.PureComponent {
    render() {
        return(
            <div>
                <Row className="whiteFont font1-title1" style={{background:'#1158e8' }}>
                    <Col span={8}  className='font1-img'><img src={yun} />  专业级智能云管理服务</Col>
                    <Col span={8}  className='font1-img'><img src={shuju}/>  覆盖行业46+</Col>
                    <Col span={8}  className='font1-img'><img src={fuwu} />  服务企业客户300家</Col>
                </Row>
                <Row className="blackFont font1-title">
                    <Col span={24} style={{textAlign:'center',paddingTop:'50px'}}>集监测、管理、优化与服务四位一体，为企业提供全面智能支持</Col>
                </Row>
                <Row className="blackFont font1-text">
                    <Col span={24} style={{textAlign:'center',paddingBottom:'50px'}}>
                        科技驱动管理创新，帮助企业提升运营效率、降低成本，并在数字化转型中保持竞争优势
                    </Col>
                </Row>
            </div>
        )
    }
}
export default Banner;
